
import React, { useState, useContext } from "react";

const CurrentContext = React.createContext();
const CurrentUpdateContext = React.createContext();

export const useCurrent = () => {
  return useContext(CurrentContext);
};

export const useCurrentUpdate = () => {
  return useContext(CurrentUpdateContext);
};

export const CurrentProvider = ({children}) => {
  const [current, setCurrent] = useState(1);

  const updateCurrent = (page) => {
    setCurrent(page);
  };

  return (
    <CurrentContext.Provider value={current}>
      <CurrentUpdateContext.Provider value={updateCurrent}>
        {children}
      </CurrentUpdateContext.Provider>
    </CurrentContext.Provider>
  );
};
